import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Section
} from "../styledComponents/section"
import { ContactSection, ContactSectionHeading, ContactSectionLedeDiv } from "../styledComponents/contact"
import Layout from "../components/layout"
import { HeroImageOverlay } from "../styledComponents/heroBanner"
import Seo from "../components/seo"
import ContactFormV2 from "../components/Forms/contact"
import Icon from "../components/icon"


const Heading = ({ level = 1, location, children }) => (
  <ContactSectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </ContactSectionHeading>
)

const Contact = ({ data, location }) => {
  return (
    <>
    <Layout location={location}>
      <Seo 
        title="Contact The Internet Consultancy Group today"
        description="Get in contact please fill in the form and we will contact you within the next 48 hours"
        url="/contact"
        />
      
        <div style={{ display: "grid" }}>
        <StaticImage
          loading="eager"
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          layout="fullWidth"
          // You can optionally force an aspect ratio for the generated image
          aspectRatio={3 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt=""
          // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
          src={
            "../images/contact.jpg"
          }
          formats={["auto", "webp", "avif"]}
        />
          <HeroImageOverlay
          >
            <Heading level={1}>
            Contact us
            </Heading>
          </HeroImageOverlay>
        </div>
        <Section>
        
        <ContactSectionLedeDiv>
          <h2>Say hello on the contact form and we can arrange a good time to talk</h2>
          <p>You can also reach us by phone, email, or WhatsApp.</p>
          <ul>
            <li><Icon name="contact" /><a href="tel:+447951069886">+44(0)7951 069 886</a></li>
            <li><Icon name="email" /><a href="mailto:hello@ticg.co.uk">hello@ticg.co.uk</a></li>
            <li><Icon name="whatsapp" /><a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send/?app_absent=0&amp;phone=447951069886">Send a WhatsApp</a></li>
          </ul>
        </ContactSectionLedeDiv>
        <ContactSection background="var(--grey-lightest)" isContactPage>
          <p>Please complete this form and we will get back to you.</p>
          <ContactFormV2 />
        </ContactSection>
        
      </Section>
    </Layout>
    </>
  )
}

export default Contact